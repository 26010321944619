import React, { CSSProperties, FC } from "react";
import { Transition } from "react-transition-group";

// Atom
const TextFader: FC<{ in: boolean }> = ({ in: inProp, children }) => {
  const defaultStyle: CSSProperties = {
    transition: `opacity 160ms ease-in-out`,
    opacity: 0,
  };
  const transitionStyles: Record<string, CSSProperties> = {
    entering: { opacity: 1, paddingTop: "0px" },
    entered: { opacity: 1, paddingTop: "0px" },
    exiting: { opacity: 0, paddingTop: "10px" },
    exited: { opacity: 0, paddingTop: "10px" },
  };
  return (
    <Transition in={inProp} timeout={160}>
      {(state) => {
        return (
          <span
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {children}
          </span>
        );
      }}
    </Transition>
  );
};
export default TextFader;

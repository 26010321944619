import React, { FC, HTMLAttributes } from "react";
import { makeStyles } from "@material-ui/core/styles";

// Atom
const SsTextBlock: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <div {...props} className={`${classes.root} ${props.className}`}>
      <div className={classes.bg} />
      {children}
    </div>
  );
};
export default SsTextBlock;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "82px",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 20px)",
      height: "47px",
    },
  },
  bg: {
    backgroundColor: "#fff",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    zIndex: -1,
    "&:before,&:after": {
      content: "''",
      display: "block",
      position: "absolute",
    },
    "&:before": {
      left: "0",
      backgroundColor: "#fff",
    },
    "&:after": {
      right: "0",
      width: "0",
      height: "0",
      borderStyle: "solid",
      borderColor: "#fff transparent transparent transparent",
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100% - 20px)",
      "&:before": {
        bottom: "-20px",
        height: "20px",
        width: "calc(100% - 20px)",
      },
      "&:after": {
        bottom: "-20px",
        borderWidth: "20px 20px 0 0",
      },
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 10px)",
      "&:before": {
        bottom: "-10px",
        height: "10px",
        width: "calc(100% - 10px)",
      },
      "&:after": {
        bottom: "-10px",
        borderWidth: "10px 10px 0 0",
      },
    },
  },
}));

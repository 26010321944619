import React, { FC } from "react";
import PlayableMovie from "components/front/project/PlayableMovie";

const MovieImage: FC<{
  imageUrl: string;
  movieUrl: string;
  isActive: boolean;
}> = ({ imageUrl, movieUrl, isActive }) => {
  return (
    <div style={{ position: "relative" }}>
      <div>
        <img src={imageUrl} width="100%" alt="" />
      </div>
      <PlayableMovie movieUrl={movieUrl} isActive={isActive} />
    </div>
  );
};
export default MovieImage;

import React, { FC, HTMLAttributes, useState } from "react";
import SsHeadline from "../layout/SsHeadline";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import SsTextBlock from "../layout/SsTextBlock";
import TextFader from "../motion/TextFader";
import { useActivation } from "lib/hooks/useActivation";
import Link from "next/link";
import BreakingText from "../../layouts/LineBreakText";
import MovieImage from "./MovieImage";
import styled from "styled-components";

SwiperCore.use([Navigation]);

export type Props = HTMLAttributes<HTMLElement> & {
  pickups: Pickup[];
};
const PickupSection: FC<Props> = ({ pickups, ...props }) => {
  const [activePickup, setActivePickup] = useState<Pickup>(pickups[0]);
  const [textShown, showText, hideText] = useActivation(true);
  return (
    <section {...props}>
      <SsHeadline color="white" label="ピックアップ" />
      <ContentRoot>
        <ExposedSwiperRoot>
          <StyledSwiper
            slidesPerView={1}
            centeredSlides={true}
            spaceBetween={10}
            breakpoints={{
              961: {
                spaceBetween: 16,
              },
            }}
            navigation
            loop={pickups.length > 2}
            loopAdditionalSlides={2}
            onTransitionEnd={(swiper) => {
              setActivePickup(pickups[swiper.realIndex]);
              showText();
            }}
            onSliderMove={() => {
              hideText();
            }}
          >
            {pickups.map((pickup, index) => (
              <SwiperSlide key={index}>
                <MovieImage imageUrl={pickup.imageUrl} movieUrl={pickup.movieUrl} isActive={pickup === activePickup} />
              </SwiperSlide>
            ))}
          </StyledSwiper>
        </ExposedSwiperRoot>
        <PickupTextBox>
          <Link href={activePickup.linkUrl}>
            <PickupText>
              <TextFader in={textShown}>
                <BreakingText text={activePickup.text} />
              </TextFader>
            </PickupText>
          </Link>
        </PickupTextBox>
      </ContentRoot>
    </section>
  );
};
export default PickupSection;

const ContentRoot = styled.div`
  position: relative;
`;
const ExposedSwiperRoot = styled.div`
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 960px) {
    width: 100%;
    padding-bottom: 10px;
  }
  @media screen and (min-width: 961px) and (max-width: 1280px) {
    width: 100vw;
    right: calc(50vw - 480px);
    padding-bottom: 16px;
  }
  @media screen and (min-width: 1281px) {
    width: 100vw;
    right: calc(50vw - 540px);
    padding-bottom: 16px;
  }
  @media screen and (min-width: 1921px) {
    width: 100vw;
    right: calc(50vw - 640px);
    padding-bottom: 16px;
  }
`;
const StyledSwiper = styled(Swiper)`
  &.swiper-container {
    overflow: visible;
    @media screen and (max-width: 960px) {
      width: 80vw;
    }
    @media screen and (min-width: 961px) {
      width: 720px;
    }
    @media screen and (min-width: 1921px) {
      width: 900px;
    }
  }

  & .swiper-button-prev,
  & .swiper-button-next {
    color: white;

    &:after {
      text-shadow: 0 0 2px black, 0 0 2px black, 0 0 3px black, 0 0 3px black, 0 0 4px black, 0 0 4px black;

      @media screen and (max-width: 960px) {
        font-size: 30px;
      }
    }
  }
  & .swiper-button-prev {
    left: -30px;
  }
  & .swiper-button-next {
    right: -30px;
  }
`;
const PickupTextBox = styled(SsTextBlock)`
  @media screen and (max-width: 960px) {
    width: calc(100vw - 20px);
    height: 47px;
  }
  @media screen and (min-width: 961px) {
    height: 82px;
    width: 960px;
  }
  @media screen and (min-width: 1281px) {
    width: 1080px;
  }
  @media screen and (min-width:1921px) {
    width: 1280px;
  }
`;
const PickupText = styled.p`
  transform-origin: center;
  overflow: hidden;
  width: 100%;
  @media screen and (max-width: 960px) {
    font-size: 12px;
    line-height: 1.34em;
  }
  @media screen and (min-width: 961px) {
    font-size: 16px;
    line-height: 1.6em;
  }
  @media screen and (min-width: 1281px) {
    font-size: 18px;
  }
`;

export type Pickup = {
  text: string;
  linkUrl: string;
  imageUrl: string;
  movieUrl: string;
};

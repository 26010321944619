import React, { FC } from "react";

// Atom
const BreakingText: FC<{ text: string }> = ({ text }) => (
  <>
    {text.split("\n").map((str, index) => (
      <React.Fragment key={index}>
        {index !== 0 && <br />}
        {str}
      </React.Fragment>
    ))}
  </>
);
export default BreakingText;
